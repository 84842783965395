import React from 'react'

import SEO from '../components/seo'

import Layout from '../components/layout'

import SectionHeader from '../components/SectionHeader/SectionHeader'
import BottomNav from '../components/BottomNav/BottomNav'
import PrivacyPolicy from '../components/PrivacyPolicy/PrivacyPolicy'

const PrivacyPolicyPage = () => {
  return (
    <Layout>
      <SEO
        title="Privacy Policy UK"
        keywords={[
          `Emersoft LTD`,
          `application`,
          `react`,
          `London`,
          `digital agency`,
        ]}
      />

      <SectionHeader paragraph='Emersoft LTD'>Personal Data Processing</SectionHeader>

      <PrivacyPolicy />

      <BottomNav
        additionalClass="bottom-nav--border-top"
        heading="See More"
        firstLink="/services/websites"
        firstHeading="Websites/Design"
        secondLink="/services/support"
        secondHeading="Supporting Services"
      />
    </Layout>
  )
}

export default PrivacyPolicyPage
