import React from 'react'

function PrivacyPolicy(props) {
  return (
    <section className="privacy-policy">
      <div className="container">
        <div className="privacy-policy__inner">
          <p className="privacy-policy__paragraph text--5">
            In this document, we describe how we process your personal data for
            the purpose of recruiting and selecting employees (this especially
            applies to your name, contact information, education and/or other
            information included in your CV), as well as for sending our
            newsletters (this especially applies to your E-mail address and
            telephone number) and/or for other marketing activities (especially
            photographs from our events and/or direct marketing).
          </p>
          <ol className="privacy-policy__list text--4">
            <li className="privacy-policy__item">
              <h3 className="privacy-policy__item-heading text--4">
                ADMINISTRATOR'S IDENTITY AND CONTACT DETAILS
              </h3>
              <ul className="privacy-policy__inner-list">
                <li className="privacy-policy__item">
                  <p className="privacy-policy__paragraph text--5">
                    The Administrator of your personal data is Emersoft LTD
                    [Company ID] 10977747, with registered seat at 71 Shelton
                    Street Covent Garden, London WC2H 9JQ (hereinafter the
                    "Administrator").
                  </p>
                </li>
                <li className="privacy-policy__item">
                  <p className="privacy-policy__paragraph text--5">
                    The administrator's contact information is as follows:
                  </p>
                  <ul className="privacy-policy__inner-sublist">
                    <li className="privacy-policy__item">
                      <p className="privacy-policy__paragraph text--5">
                        Address: 71 Shelton Street Covent Garden, London WC2H
                        9JQ
                      </p>
                    </li>
                    <li className="privacy-policy__item">
                      <p className="privacy-policy__paragraph text--5">
                        E-mail:
                        <a
                          href="mailto:hello@emersoft.co"
                          className="footer__contact-link
                                      privacy-policy__link"
                        >
                          hello@emersoft.co
                        </a>
                      </p>
                      <p className="privacy-policy__paragraph text--5">
                        Tel:
                        <a
                          href="tel:+17027572008"
                          className="footer__contact-link
                                      privacy-policy__link"
                        >
                          +1 702 757 2008
                        </a>
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <li className="privacy-policy__item">
              <h3 className="privacy-policy__item-heading text--4">
                REASON FOR PROCESSING PERSONAL DATA
              </h3>
              <ul className="privacy-policy__inner-list">
                <li className="privacy-policy__item">
                  <p className="privacy-policy__paragraph text--5">
                    The legal reason for processing your personal data is, in
                    case of processing of personal data for the purpose of
                    selection of employees, negotiations of an employment and/or
                    other contract, which is in keeping with Article 6.1 b. of
                    the Regulation `(EU)` 2016/679 of the European Parliament
                    and of the Council of 27 April 2016 on the protection of
                    natural persons with regard to the processing of personal
                    data and on the free movement of such data, and repealing
                    Directive 95/46/EC `(General Data Protection Regulation)`
                    `(hereinafter the "GDPR")`. In case of processing of
                    personal information for the purpose of marketing
                    activities, the legal reason is the Administrator's
                    legitimate interest, which is in keeping with Article 6.1 f.
                    of the GDPR.
                  </p>
                </li>
              </ul>
            </li>
            <li className="privacy-policy__item">
              <h3 className="privacy-policy__item-heading text--4">
                WHY ARE WE PROCESSING YOUR PERSONAL DATA
              </h3>
              <ul className="privacy-policy__inner-list">
                <li className="privacy-policy__item">
                  <p className="privacy-policy__paragraph text--5">
                    Your personal data are being processed solely for the
                    purpose of recruitment and selection of employees, sending
                    of our newsletters and implementation of other marketing
                    activities by the Administrator `(publication of photographs
                    from our events and/or direct marketing)`.
                  </p>
                </li>
              </ul>
            </li>
            <li className="privacy-policy__item">
              <h3 className="privacy-policy__item-heading text--4">
                HOW LONG DO WE KEEP YOUR PERSONAL DATA
              </h3>
              <ul className="privacy-policy__inner-list">
                <li className="privacy-policy__item">
                  <p className="privacy-policy__paragraph text--5">
                    When you send us your CV, we will process your personal data
                    for the duration of the selection process for the given
                    position, but no longer than 6 months from receiving your
                    CV, unless you give us consent with archiving your CV for
                    longer.
                  </p>
                </li>
                <li className="privacy-policy__item">
                  <p className="privacy-policy__paragraph text--5">
                    If you are our client, we will be sending you our
                    newsletters to your E-mail `(and/or telephone number)` for a
                    period of 3 years from your last PO.
                  </p>
                </li>
                <li className="privacy-policy__item">
                  <p className="privacy-policy__paragraph text--5">
                    When we take photos at our events, we will keep such photos
                    in our archive for the duration of our company and reserve
                    the right to use them on Websites and/or social networks for
                    a period of 10 years.
                  </p>
                </li>
              </ul>
            </li>
            <li className="privacy-policy__item">
              <h3 className="privacy-policy__item-heading text--4">
                WHOM DO WE GIVE YOUR PERSONAL DATA TO
              </h3>
              <ul className="privacy-policy__inner-list">
                <li className="privacy-policy__item">
                  <p className="privacy-policy__paragraph text--5">
                    We may authorise a third party to process your personal
                    data. A Processor of personal data is an entity which, based
                    on special legislation or our authorisation or authorisation
                    by the Administrator, processes personal data. In such
                    cases, your personal data is guaranteed `(contractually, or
                    based on legislation)` to be subject to the same protection
                    as when processed by the Administrator.
                  </p>
                </li>
                <li className="privacy-policy__item">
                  <p className="privacy-policy__paragraph text--5">
                    At the moment, we are using the following Processors:
                  </p>
                  <ul className="privacy-policy__inner-sublist">
                    <li className="privacy-policy__item">
                      <p className="privacy-policy__paragraph text--5">
                        Co-operators on marketing services `(consultants,
                        copywriters, strategists and other entities cooperating
                        with the Administrator as subcontractors)`
                      </p>
                    </li>
                    <li className="privacy-policy__item">
                      <p className="privacy-policy__paragraph text--5">
                        Google
                      </p>
                    </li>
                    <li className="privacy-policy__item">
                      <p className="privacy-policy__paragraph text--5">
                        MailChimp
                      </p>
                    </li>
                  </ul>
                </li>
                <li className="privacy-policy__item">
                  <p className="privacy-policy__paragraph text--5">
                    As an Administrator, we do not intend to transfer your
                    personal data to any third country `(a country outside of
                    the EU)` or any international organisation.
                  </p>
                </li>
              </ul>
            </li>
            <li className="privacy-policy__item">
              <h3 className="privacy-policy__item-heading text--4">
                WHOM DO WE GIVE YOUR PERSONAL DATA TO
              </h3>
              <ul className="privacy-policy__inner-list">
                <li className="privacy-policy__item">
                  <p className="privacy-policy__paragraph text--5">
                    We may authorise a third party to process your personal
                    data. A Processor of personal data is an entity which, based
                    on special legislation or our authorisation or authorisation
                    by the Administrator, processes personal data. In such
                    cases, your personal data is guaranteed `(contractually, or
                    based on legislation)` to be subject to the same protection
                    as when processed by the Administrator.
                  </p>
                </li>
                <li className="privacy-policy__item">
                  <p className="privacy-policy__paragraph text--5">
                    At the moment, we are using the following Processors:
                  </p>
                  <ul className="privacy-policy__inner-sublist">
                    <li className="privacy-policy__item">
                      <p className="privacy-policy__paragraph text--5">
                        Co-operators on marketing services (consultants,
                        copywriters, strategists and other entities cooperating
                        with the Administrator as subcontractors)
                      </p>
                    </li>
                    <li className="privacy-policy__item">
                      <p className="privacy-policy__paragraph text--5">
                        Google
                      </p>
                    </li>
                    <li className="privacy-policy__item">
                      <p className="privacy-policy__paragraph text--5">
                        MailChimp
                      </p>
                    </li>
                  </ul>
                </li>
                <li className="privacy-policy__item">
                  <p className="privacy-policy__paragraph text--5">
                    As an Administrator, we do not intend to transfer your
                    personal data to any third country (a country outside of the
                    EU) or any international organization.
                  </p>
                </li>
              </ul>
            </li>
            <li className="privacy-policy__item">
              <h3 className="privacy-policy__item-heading text--4">
                HOW IS YOUR PERSONAL DATA SECURED
              </h3>
              <ul className="privacy-policy__inner-list">
                <li className="privacy-policy__item">
                  <p className="privacy-policy__paragraph text--5">
                    We know how your privacy and security of your personal data
                    is important. We, therefore, strive to maintain a high level
                    of security during the processing of your personal data so
                    that your data cannot be misused.
                  </p>
                </li>
                <li className="privacy-policy__item">
                  <p className="privacy-policy__paragraph text--5">
                    Your data may be processed manually or automatically.
                    Automated processing takes place in our information systems
                    and/or our processors' information systems.
                  </p>
                </li>
                <li className="privacy-policy__item">
                  <p className="privacy-policy__paragraph text--5">
                    Your personal data are processed by our employees and/or
                    other processors. We always sign a written processing
                    agreement with all processors of your personal data. These
                    agreements include the same guarantees as those applying to
                    the processing of personal data within SYMBIO.
                  </p>
                </li>
              </ul>
            </li>
            <li className="privacy-policy__item">
              <h3 className="privacy-policy__item-heading text--4">COOKIES</h3>
              <ul className="privacy-policy__inner-list">
                <li className="privacy-policy__item">
                  <p className="privacy-policy__paragraph text--5">
                    Our Website uses cookies to:
                  </p>
                  <ul className="privacy-policy__inner-sublist">
                    <li className="privacy-policy__item">
                      <p className="privacy-policy__paragraph text--5">
                        Measure site traffic and provide statistics on traffic
                        and user behavior;
                      </p>
                    </li>
                    <li className="privacy-policy__item">
                      <p className="privacy-policy__paragraph text--5">
                        Improve functionality of the site.
                      </p>
                    </li>
                  </ul>
                </li>
                <li className="privacy-policy__item">
                  <p className="privacy-policy__paragraph text--5">
                    Collecting cookies may be considered processing of personal
                    data. Such processing is possible for a legal reason -
                    legitimate interest of the Administrator and is in keeping
                    with Article 6.1 f. of the GDPR.
                  </p>
                </li>
                <li className="privacy-policy__item">
                  <p className="privacy-policy__paragraph text--5">
                    Websites may also be used in a mode preventing the site's
                    owner to collect information on user behavior – this mode
                    may be selected as part of browser settings, or an objection
                    may be made against such collection of information as per
                    Article 21 of the GDPR. Such objection shall be sent to
                    info@symbio.agency. Objections shall be evaluated without
                    unnecessary delays and no later than 30 days from receipt.
                    Cookies necessary for Website functionality will only be
                    stored for the time necessary for such functionality.
                  </p>
                </li>
                <li className="privacy-policy__item">
                  <p className="privacy-policy__paragraph text--5">
                    When you make an objection to processing of technical
                    cookies necessary for our Website's functionality, full
                    function and compatibility of the Website are not
                    guaranteed.
                  </p>
                </li>
                <li className="privacy-policy__item">
                  <p className="privacy-policy__paragraph text--5">
                    Cookies collected for the purpose of measuring site traffic
                    and providing traffic and user behavior statistics are
                    analysed as part of cumulative data and in a pseudonymised
                    version.
                  </p>
                </li>
                <li className="privacy-policy__item">
                  <p className="privacy-policy__paragraph text--5">
                    Cookies are stored for a maximum of 13 months from their
                    last use.
                  </p>
                </li>
                <li className="privacy-policy__item">
                  <p className="privacy-policy__paragraph text--5">
                    Cookies are processed by the provider of the Google
                    Analytics service, Google Inc., residing at 1600
                    Amphitheatre Parkway, Mountain View, CA 94043, USA in
                    keeping with their terms and conditions, which are available
                    at:
                  </p>
                  <ul className="privacy-policy__inner-sublist">
                    <li className="privacy-policy__item">
                      <a
                        href="https://policies.google.com/privacy/update?hl=en"
                        className="footer__contact-link text--5"
                      >
                        Policies Google Privacy
                      </a>
                    </li>
                    <li className="privacy-policy__item">
                      <a
                        href="https://policies.google.com/technologies/cookies?hl=en"
                        className="footer__contact-link text--5"
                      >
                        Policies Google Technologies
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>

            <li className="privacy-policy__item">
              <h3 className="privacy-policy__item-heading text--4">
                WHAT ARE YOUR RIGHTS
              </h3>
              <ul className="privacy-policy__inner-list">
                <li className="privacy-policy__item">
                  <p className="privacy-policy__paragraph text--5">
                    Under the terms of the GDPR, you have the right to request
                    access to your personal data from the Administrator, right
                    to request a copy of your personal data from us, right to
                    correction or deletion of your personal data and/or right to
                    request limited processing of your personal data and right
                    to portability of your personal data when such data were
                    obtained by automated processing based on your consent, or
                    contract performance.
                  </p>
                </li>
                <li className="privacy-policy__item">
                  <p className="privacy-policy__paragraph text--5">
                    You HAVE THE RIGHT TO OBJECT to processing of your E-mail
                    address (and/or telephone number) for the purpose of sending
                    newsletters when such newsletters are being sent based on
                    our legitimate interest – i.e. you are our customer.
                    Similarly, you have the right to object to processing of
                    other personal data based on legitimate interest of the
                    Administrator – i.e. processing of cookies. Your objection
                    will be evaluated without delay.
                  </p>
                </li>
                <li className="privacy-policy__item">
                  <p className="privacy-policy__paragraph text--5">
                    Should you feel that processing of your personal data
                    resulted in a breach of the GDPR, you have the right, among
                    other things, to complain to the Office for Protection of
                    Personal Data and/or to appeal to a court.
                  </p>
                </li>
              </ul>
            </li>
          </ol>
        </div>
      </div>
    </section>
  )
}

export default PrivacyPolicy
